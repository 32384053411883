import React, { useEffect, useState } from 'react'
import DRGPageToolBar from '../../../components/DRGPageToolBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PolylineIcon from '@mui/icons-material/Polyline';
import GroupIcon from '@mui/icons-material/Group';
import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenDrawerLeft, setOpenDrawerLeft } from '../../../redux/drgMgtSlice';
import DRGOutlet from './DRGOutlet';
import SubjectPage from './Subject/SubjectPage';
import ObjectPage from './Object/ObjectPage';
import RegulatoryPage from './Regulatory/RegulatoryPage';

function DRGLayout() {

    const location = useLocation();
    const currentTab = '/' + location.pathname.split('/')[2];

    const openDrawerLeft = useSelector(getOpenDrawerLeft);
    const dispatch = useDispatch();
    const [tabSelected, setTabSelected] = useState("");
    const [tabValue, setTabValue] = useState(0);

    const habdleTabClick = (event) => {
        if (location.pathname.split('/')[2] === tabSelected) {
            dispatch(setOpenDrawerLeft(!openDrawerLeft));
        }

    }

    useEffect(() => {
        if (location.pathname) {
            setTabSelected(location.pathname.split('/')[2])
            dispatch(setOpenDrawerLeft(true));
        }
    }, [location.pathname])

    useEffect(() => {
        if (currentTab === '/subject') {
            setTabValue(0);
        } else if (currentTab === '/object') {
            setTabValue(1);
        } else if (currentTab === '/regulatory') {
            setTabValue(2);
        }
    }, [currentTab])

    return (
        <Box sx={{ overflow: 'hidden', bgColor: '#f3f3f3' }}>
            <DRGPageToolBar />
            <div className='flex mt-[52px] h-[calc(100vh-52px)]'>
                <Tabs
                    orientation="vertical"
                    value={tabValue}
                    aria-label="drg tabs"
                    sx={{
                        borderRight: 1,
                        borderColor: 'divider',
                        // bgcolor: 'background.paper', 
                        bgcolor: '#2a334a',
                        minWidth: 70,
                        flex: 1,
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    textColor='inherit'
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: 'white',
                        },
                    }}
                >
                    <Tooltip
                        title="Subject"
                        placement="right"
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Tab
                            label="Subject"
                            // value="/subject"
                            value={0}
                            name="subject"
                            component={Link}
                            to="/drg/subject"
                            icon={<GroupIcon sx={{ width: 20, height: 20, color: currentTab === "/subject" ? '#ffffff' : '#707991' }} />}
                            sx={{
                                // boxShadow: (currentTab === "/subject" && 3),
                                textTransform: 'none',
                                fontSize: 11,
                                padding: 1.5,
                                minWidth: 50,
                                color: currentTab === "/subject" ? '#ffffff' : '#707991',
                                bgcolor: currentTab === "/subject" && 'rgb(15 23 42)',
                            }}
                            className='hover:bg-slate-900 cursor-pointer'
                            onClick={habdleTabClick}
                        />
                    </Tooltip>
                    <Tooltip
                        title="Object"
                        placement="right"
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Tab
                            label="Object"
                            // value="/object"
                            value={1}
                            name="object"
                            component={Link}
                            to="/drg/object"
                            icon={<ShapeLineIcon sx={{ width: 20, height: 20, color: currentTab === "/object" ? '#ffffff' : '#707991' }} />}
                            sx={{
                                // boxShadow: (currentTab === "/object" && 3),
                                textTransform: 'none',
                                fontSize: 11,
                                padding: 1.5,
                                minWidth: 50,
                                color: currentTab === "/object" ? '#ffffff' : '#707991',
                                bgcolor: currentTab === "/object" && 'rgb(15 23 42)',
                            }}
                            className='hover:bg-slate-900 cursor-pointer'
                            onClick={habdleTabClick}
                        />
                    </Tooltip>
                    <Tooltip
                        title="Regulatory"
                        placement="right"
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Tab
                            label="Regulatory"
                            // value="/regulatory"
                            value={2}
                            name="regulatory"
                            component={Link}
                            to="/drg/regulatory"
                            icon={<PolylineIcon sx={{ width: 20, height: 20, color: currentTab === "/regulatory" ? '#ffffff' : '#707991' }} />}
                            sx={{
                                // boxShadow: (currentTab === "/regulatory" && 3),
                                textTransform: 'none',
                                fontSize: 11,
                                padding: 1.5,
                                minWidth: 50,
                                color: currentTab === "/regulatory" ? '#ffffff' : '#707991',
                                bgcolor: currentTab === "/regulatory" && 'rgb(15 23 42)',
                            }}
                            className='hover:bg-slate-900 cursor-pointer'
                            onClick={habdleTabClick}
                        />
                    </Tooltip>
                </Tabs>
                <Routes>
                    <Route path="drg/*" element={<DRGOutlet />} />
                    <Route path="subject/*" element={<SubjectPage />} />
                    <Route path="object/*" element={<ObjectPage />} />
                    <Route path="regulatory/*" element={<RegulatoryPage />} />
                    <Route path="/" element={<Navigate to="/drg/subject" />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        </Box>
    )
}

export default DRGLayout