import React, { useEffect, useState } from 'react'
import { Handle, Position } from '@xyflow/react';
import { Typography, useTheme } from '@mui/material';

const SelectionNode = ({ data, selected }) => {

    const labelRef = React.createRef();

    const theme = useTheme();
    const minWidth = 100;
    const minHeight = 38;
    const pX = 15;

    const [L, setL] = useState(minWidth);
    const [W, setW] = useState(minWidth + pX * 2);

    useEffect(() => {
        let L = labelRef.current.offsetWidth;
        L = L <= minWidth - pX * 2 ? minWidth - pX * 2 : L;
        setL(L)
        setW(L + pX * 2)
    }, [data.general_properties.label])

    return (
        <div className={`h-[${minHeight}]`}>
            <svg width={W} height={minHeight} >
                <g>
                    <path d={`M0,${minHeight / 2} L${pX},0 L${L + pX},0 L${W},${minHeight / 2} L${L + pX},${minHeight} L${pX},${minHeight} Z`}
                        fill="#fff"
                        strokeWidth="1"
                        // stroke={selected ? theme.palette.primary.main : theme.palette.grey[500]}
                        stroke={selected ? theme.palette.primary.main : theme.palette.grey[500]}
                    >
                    </path>
                </g>
            </svg>
            <Typography
                className={`absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center whitespace-nowrap`}
                variant='caption'
                fontSize={{
                    lg: 12,
                    md: 10,
                    sm: 10,
                    xs: 10
                }}
                ref={labelRef}
            >
                {data.general_properties.label}
            </Typography>
            <Handle
                id="top-target"
                type="target"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="top-source"
                type="source"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="bottom-target"
                type="target"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="bottom-source"
                type="source"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="left-target"
                type="target"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="left-source"
                type="source"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="right-target"
                type="target"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="right-source"
                type="source"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
        </div>

    );
};

export default SelectionNode;