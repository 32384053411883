import React, { useState } from 'react'
import { Handle, Position } from '@xyflow/react';
import { Typography, useTheme } from '@mui/material';

const ConjunctionNode = ({ data, selected }) => {

    const theme = useTheme();

    return (
        <div style={{
            padding: '10px 15px',
            backgroundColor: '#fff',
            // border: selected ? `1px dashed ${theme.palette.primary.main}` : `1px dashed ${theme.palette.grey[500]}`,
            border: selected ? `1px dashed ${theme.palette.primary.main}` : `1px dashed ${theme.palette.grey[500]}`,
            borderRadius: '5px',
            display: 'inline-block',
            textAlign: 'center',
        }}
        >
            <div>
                <Typography
                    variant='subtitle2'
                    fontSize={{
                        lg: 12,
                        md: 10,
                        sm: 10,
                        xs: 10
                    }}
                    sx={{
                        cursor: 'pointer',
                        textAlign: 'center',
                    }}
                >
                    {data.general_properties.label}
                </Typography>
            </div>
            <Handle
                id="top-target"
                type="target"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="top-source"
                type="source"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="bottom-target"
                type="target"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="bottom-source"
                type="source"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="left-target"
                type="target"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="left-source"
                type="source"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="right-target"
                type="target"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="right-source"
                type="source"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
        </div>
    );
};

export default ConjunctionNode;