import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from '../../../../../redux/projectSlice';
import { getDpeConfig } from '../../../../../redux/dpeConfigSlice';
import { v4 as uuidv4 } from 'uuid';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function SelectResourceDialog(props) {

    const theme = useTheme();
    const { open, setOpen, setNodes, nodeSelected } = props;
    // const project = useSelector(getProject);
    // const project = JSON.parse(localStorage.getItem('project'));
    // const dpeConfig = useSelector(getDpeConfig);
    const [disabled, setDisabled] = useState(true);
    const [dpes, setDpes] = useState([]);
    const [dpeSelected, setDpeSelected] = useState("");
    const [resources, setResources] = useState([]);
    const [resourceSelected, setResourceSelected] = useState("");
    const [project, setProject] = useState();
    const [dpeConfig, setDpeConfig] = useState();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
        const dpeConfig = JSON.parse(localStorage.getItem('dpe-config'));
        if (dpeConfig) {
            setDpeConfig(dpeConfig);
        }
    }, []);

    const handleClose = () => {
        setDpes([]);
        setResources([]);
        setDpeSelected("");
        setResourceSelected("");
        setOpen(false);
    };

    const getResourceAttributeList = async () => {
        const iaam = window.iaam;
        const accessToken = await iaam.getAccessToken();
        let option = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `bearer ${accessToken}`
            }
        }
        const response = await fetch(`${dpeConfig.resource_schema_endpoint}?resource=${resourceSelected}`, option);
        if (response.ok) {
            const result = await response.json();
            const new_result = result.map((attr) => {
                return {
                    name: attr.column_name,
                    data_type: attr.data_type,
                    id: uuidv4(),
                    checked: true
                };
            });
            setNodes((nds) => nds.map((node) => {
                if (node.id === nodeSelected.id) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            general_properties: {
                                ...node.data.general_properties,
                                resource: {
                                    dpe: dpeSelected,
                                    resource_label: resourceSelected
                                },
                                attribute_selections: new_result
                            }
                        }
                    };
                }
                return node;
            }));
            handleClose();
        } else {
            console.error(response);
        }
    };

    const handleSelect = () => {
        getResourceAttributeList();
    };

    const getDpeList = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/dpe/get-dpes/${project.project.project_id}`);
        if (response.status === 200) {
            const result = await response.json();
            setDpes(result);
        } else {
            console.error(response.status);
        }
    };

    const getResourceList = async () => {
        const iaam = window.iaam;
        const accessToken = await iaam.getAccessToken();
        let option = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `bearer ${accessToken}`
            }
        }
        const response = await fetch(`${dpeConfig.resources_endpoint}`, option);
        if (response.ok) {
            const result = await response.json();
            setResources(result)
        } else {
            console.error(response);
        }
    };

    const updateNodeSelected = useCallback(
        () => {
            if (nodeSelected?.data?.general_properties?.resource !== undefined) {
                setDpeSelected(nodeSelected.data.general_properties.resource.dpe);
                setResourceSelected(nodeSelected.data.general_properties.resource.resource_label);
            } else {
                setDpeSelected("");
                setResourceSelected("");
            }
        },
        [nodeSelected],
    )

    useEffect(() => {
        if (project && dpeConfig && open) {
            getDpeList();
            getResourceList();
        }
    }, [project, dpeConfig, open]);

    useEffect(() => {
        if (dpeSelected && resourceSelected) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [dpeSelected, resourceSelected])

    useEffect(() => {
        if (open) {
            updateNodeSelected();
        }
    }, [open])

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth
                maxWidth="xs"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 2, borderTopRightRadius: 2 }} id="customized-dialog-title">
                    <div>
                        <Typography variant='h6' color={theme.palette.white.main}>Select Resource</Typography>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: theme.palette.white.main
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box sx={{ paddingX: 3, paddingY: 2 }}>
                        <Autocomplete
                            id="combo-box-dpe"
                            options={dpes}
                            getOptionLabel={(option) => option.dpe_name ? option.dpe_name : dpeSelected}
                            // isOptionEqualToValue={(option, value) => option.dpe_name === value.dpe_name}
                            value={dpeSelected}
                            onChange={(event, newValue) => {
                                setDpeSelected(newValue);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setDpeSelected(newInputValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Data Proxy Engine" />}
                        />
                        <Autocomplete
                            id="combo-box-resource"
                            value={resourceSelected ? resourceSelected : ''}
                            onInputChange={(event, newInputValue) => {
                                setResourceSelected(newInputValue);
                            }}
                            options={resources}
                            isOptionEqualToValue={(option, value) => option === value}
                            sx={{ marginTop: 2 }}
                            renderInput={(params) => <TextField {...params} label="Resource" />}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ m: 0, paddingX: 1, paddingY: 1 }}>
                        <Button variant="contained" disabled={disabled} onClick={() => handleSelect()}>Select</Button>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </Fragment>
    )
}

export default SelectResourceDialog