import { styled, Tooltip, tooltipClasses } from "@mui/material";

export const WarningTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffc107',
        color: 'black',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        "& .MuiTooltip-arrow": {
            color: "#ffc107",
        },
    },
}));