import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import { ListItem, Stack, useTheme } from '@mui/material';
import ProfileCard from './ProfileCard';

export default function EditorPageToolBar() {
    const drawerWidth = 300;

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color="white" sx={{ bgcolor: '#ffffff' }}>
                <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                    <Typography variant='h6' component='div' color="primary" sx={{ flexGrow: 1, fontWeight: 'Bold' }}>
                        IAAM Policy Suite
                    </Typography>
                    <Box sx={{ display: 'flex', width: drawerWidth, alignItems: 'center' }}>
                        <div className='flex grow justify-center'>
                            <Box
                                sx={{
                                    bgcolor: theme.palette.primary.main,
                                    padding: '6px',
                                    border: 1,
                                    borderColor: theme.palette.primary.main,
                                    borderRight: 0,
                                    borderTopLeftRadius: 3,
                                    borderBottomLeftRadius: 3,
                                }}
                            >
                                <Typography color={theme.palette.white.main} variant='subtitle2'>OP</Typography>
                            </Box>
                            <Box
                                sx={{
                                    padding: '6px',
                                    border: 1,
                                    borderLeft: 0,
                                    borderColor: '#ccc',
                                    borderTopRightRadius: 3,
                                    borderBottomRightRadius: 3
                                }}
                            >
                                <Typography color={theme.palette.gray.main} variant='subtitle2'>{process.env.REACT_APP_OP_HOST}</Typography>
                            </Box>
                        </div>
                        <IconButton
                            className='flex-none'
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="primary"
                        >
                            <AccountCircle sx={{ width: 28, height: 28, mr: "10px" }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            MenuListProps={{ disablePadding: true }}
                        >
                            <ProfileCard setAnchorEl={setAnchorEl} />
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
