import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useTheme } from '@mui/material'
import React, { Fragment } from 'react'
import CloseIcon from '@mui/icons-material/Close';

function DialogReuse(props) {

    const { open, onClose, title, content, actions, maxWidth, fullWidth } = props;

    const theme = useTheme();

    return (
        <Fragment>
            <Dialog
                fullWidth= {fullWidth}
                maxWidth={maxWidth}
                open={open}
            >
                <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.white.main }}>
                    {title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: theme.palette.white.main
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {content}
                </DialogContent>
                <DialogActions>
                    <div className='px-4 py-1'>
                        {actions}
                    </div>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default DialogReuse