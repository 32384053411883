import { createSlice } from '@reduxjs/toolkit'

export const objectFileSlice = createSlice({
    name: 'objectFile',
    initialState: {
        result: undefined,
        object_files: []
    },
    reducers: {
        setObjectFile: (state, action) => {
            state.result = action.payload;
        },
        clearObjectFile: (state) => {
            state.result = undefined;
        },
        setReduxObjectFiles: (state, action) => {
            state.object_files = action.payload;
        },
    },
})

export const { setObjectFile, clearObjectFile, setReduxObjectFiles } = objectFileSlice.actions;

export const getObjectFile = (state) => state.objectFile.result;
export const getReduxObjectFiles = (state) => state.objectFile.object_files;

export default objectFileSlice.reducer