import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import { Breadcrumbs, Tooltip, useTheme } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ProfileCard from './ProfileCard';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { getSubjectFile } from '../redux/subjectFileSlice';
import { getOpenRightSide, setOpenRightSide } from '../redux/openRightSideSlice';
import { getObjectFile } from '../redux/objectFileSlice';
import { getRegulatoryFile } from '../redux/regulatoryFileSlice';

function DRGPageToolBar() {

    const theme = useTheme();
    const drawerWidth = 300;
    const dispatch = useDispatch();
    const location = useLocation();
    const currentTab = location.pathname.split('/')[2];
    const [anchorEl, setAnchorEl] = useState(null);
    const subjectFile = useSelector(getSubjectFile);
    const objectFile = useSelector(getObjectFile);
    const regulatoryFile = useSelector(getRegulatoryFile);
    const openRightSide = useSelector(getOpenRightSide);
    const [rightSide, setRightSide] = useState(false);
    const [project, setProject] = useState();
    const [currentFileName, setCurrentFileName] = useState();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickOpenRightSide = () => {
        dispatch(setOpenRightSide(!openRightSide));
    };

    useEffect(() => {
        if (currentTab === 'subject' && subjectFile) {
            setRightSide(true);
            setCurrentFileName(subjectFile.subject_diagram_name);
        } else if (currentTab === 'object' && objectFile) {
            setRightSide(true);
            setCurrentFileName(objectFile.object_diagram_name);
        } else if (currentTab === 'regulatory' && regulatoryFile) {
            setRightSide(true);
            setCurrentFileName(regulatoryFile.regulatory_diagram_name);
        } else {
            setRightSide(false);
            setCurrentFileName(undefined);
        }
    }, [currentTab, subjectFile, objectFile, regulatoryFile])

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="fixed"
                color="white"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 2,
                    bgcolor: 'rgb(255 255 255)',
                    // bgcolor: 'rgb(241 245 249)',
                    // borderBottom: 1,
                    // borderColor: '#ccc',
                }}
            >
                <Toolbar variant="dense" disableGutters>
                    <Tooltip
                        title="Project"
                        placement="right"
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Box
                            sx={{
                                minWidth: 70,
                                textAlign: 'center',
                                bgcolor: '#2a334a',
                                height: '52px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            className='hover:text-whit'
                        >
                            <IconButton className='hover:text-white' sx={{ color: '#707991' }} aria-label="home" component={Link} to="/editor/workspace">
                                <HomeIcon />
                            </IconButton>
                        </Box>
                    </Tooltip>
                    <Box sx={{
                        flexGrow: 1,
                    }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography variant='body1' color="#2a334a" className='pl-3'>
                                {project?.project?.project_name}
                            </Typography>
                            {currentFileName && <Typography variant='body1' color="#2a334a">
                                {currentFileName}
                            </Typography>}
                        </Breadcrumbs>
                    </Box>
                    {rightSide &&
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => onClickOpenRightSide()}
                        >
                            <ViewSidebarIcon sx={{ width: 25, height: 25 }} color={openRightSide ? "primary" : "action"} />
                        </IconButton>
                    }
                    <Box
                        sx={rightSide ?
                            {
                                width: drawerWidth,
                                borderLeft: 1,
                                borderColor: '#ccc',
                                display: 'flex',
                                alignItems: 'center',
                            } : {
                                width: drawerWidth,
                                display: 'flex',
                                alignItems: 'center',

                            }
                        }
                    >
                        <div className='flex grow justify-center'>
                            <Box
                                sx={{
                                    bgcolor: theme.palette.primary.main,
                                    // bgcolor: '#2a334a',
                                    padding: '6px',
                                    border: 1,
                                    borderColor: theme.palette.primary.main,
                                    borderRight: 0,
                                    borderTopLeftRadius: 3,
                                    borderBottomLeftRadius: 3,
                                }}
                            >
                                <Typography color={theme.palette.white.main} variant='subtitle2'>OP</Typography>
                            </Box>
                            <Box
                                sx={{
                                    bgcolor: theme.palette.white.main,
                                    padding: '6px 8px',
                                    border: 1,
                                    borderLeft: 0,
                                    borderColor: '#ccc',
                                    borderTopRightRadius: 3,
                                    borderBottomRightRadius: 3
                                }}
                            >
                                <Typography color={theme.palette.gray.main} variant='subtitle2'>{process.env.REACT_APP_OP_HOST}</Typography>
                            </Box>
                        </div>
                        <IconButton
                            className='flex-none'
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="primary"
                        >
                            <AccountCircle sx={{ width: 28, height: 28, mr: "10px" }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            MenuListProps={{ disablePadding: true }}
                        >
                            <ProfileCard setAnchorEl={setAnchorEl} />
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default DRGPageToolBar