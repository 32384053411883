import { Box, Button, debounce, InputBase, LinearProgress, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import CreateProjectDialog from '../../components/CreateProjectDialog';
import { format } from 'date-fns';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGridPro, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { setProject } from '../../redux/projectSlice';
import { useDispatch } from 'react-redux';
import EditProjectDialog from '../../components/EditProjectDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import { setDpeConfig } from '../../redux/dpeConfigSlice';
import ConfirmDialogReuse from './DRG/ReuseComponents/ConfirmDialogReuse';
import ResponseDialog from './DRG/ReuseComponents/ConfirmDialogReuse';
import { LoadingButton } from '@mui/lab';

function ProjectPage() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [statusUpdate, setStatusUpdate] = useState(false);
    const [projects, setProjects] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [projectSelected, setProjectSelected] = useState();
    const [openResponse, setOpenResponse] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [searchValue, setSearchValue] = React.useState("");

    const apiRef = useGridApiRef();

    const columns = [
        {
            field: 'project_name',
            headerName: 'Project Name',
            minWidth: 300,
            flex: 1,
            renderCell: (params) => {
                const onClick = () => {
                    handleProjectSelected(params.row)
                };
                return <a href="#" className="text-blue-900 font-primary" onClick={onClick}>{params.row.project_name}</a>;
            }
        },
        {
            field: 'creator_name',
            headerName: 'Create by',
            width: 220
        },
        {
            field: 'update_timestamp',
            headerName: 'Date Modified',
            width: 200
        },
        {
            field: 'actions',
            type: 'actions',
            width: 60,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<MoreVertIcon />}
                    label="Action"
                    onClick={(e) => handleActionClick(params.row, e)}
                />,
            ],
        },
    ];

    const getProject = async (row) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/project/get-project/${row.project_id}`);
        if (response.ok) {
            const result = await response.json();
            dispatch(setProject(result));
            localStorage.setItem('project', JSON.stringify(result));
            navigate('/editor/workspace');
        } else {
            console.error(response);
        }
    };

    const handleProjectSelected = async (row) => {
        handleClearLocalStorage();
        const response_dpe_config = await fetch(`https://dpe.iaam.cloud/.well-known/dpe-configuration`);
        if (response_dpe_config.ok) {
            const result = await response_dpe_config.json();
            dispatch(setDpeConfig(result));
            localStorage.setItem('dpe-config', JSON.stringify(result));
            getProject(row);
        } else {
            console.error(response_dpe_config);
        }
    }

    const handleClearLocalStorage = () => {
        const keysToRemove = ['dpe-config', 'project', 'subject-diagram', 'subject-file', 'object-diagram', 'object-file', 'regulatory-diagram', 'regulatory-file'];
        keysToRemove.forEach((key) => {
            localStorage.removeItem(key);
        });
    };

    const handleActionClick = (row, e) => {
        setProjectSelected(row)
        setAnchorEl(e.currentTarget);
    }

    const handleEditClick = () => {
        setAnchorEl(null);
        setOpenEdit(true);
    };

    const handleDeleteClick = () => {
        setAnchorEl(null);
        setOpenDelete(true);
    };

    const handleOpenCreateProject = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getListProjects = async () => {
        setIsLoading(true)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/project/get-projects`);
        if (response.ok) {
            const result = await response.json();
            const updatedItems = result.projects.map((item, index) => ({
                ...item,
                id: index,
                update_timestamp: format(new Date(item.update_timestamp), "yyyy-MM-dd HH:mm:ss"),
            }));
            setProjects(updatedItems);
            setIsLoading(false)
        } else {
            console.log(response)
            setProjects([]);
            setIsLoading(false)
        }
    }

    const handleDelete = async () => {
        setLoading(true);

        const requestOptions = {
            method: "DELETE"
        };

        const response = await fetch(process.env.REACT_APP_API_URL + `/project/delete-project/${projectSelected.project_id}`, requestOptions);
        if (response.status === 200) {
            dispatch(setProject(undefined));
            localStorage.removeItem('project');
            setProjectSelected(null);
            setStatusUpdate(true);
            setLoading(false);
            onDeleteClose();
            setResponseStatus("The project and their associated diagrams have been deleted.");
            setOpenResponse(true);
        } else {
            console.error(response.status);
            setLoading(false);
            setResponseStatus(`Error status: ${response.status}`);
            setOpenResponse(true);
        }

    };

    useEffect(() => {
        getListProjects();
    }, [])

    useEffect(() => {
        if (statusUpdate) {
            getListProjects();
            setStatusUpdate(false);
        }
    }, [statusUpdate])

    const updateSearchValue = React.useMemo(() => {
        return debounce((newValue) => {
            apiRef.current.setQuickFilterValues(
                newValue.split(" ").filter((word) => word !== "")
            );
        }, 500);
    }, [apiRef]);

    function handleSearchValueChange(event) {
        const newValue = event.target.value;
        setSearchValue(newValue);
        updateSearchValue(newValue);
    }

    const onDeleteClose = () => {
        setOpenDelete(false);
    };

    const onResponseClose = () => {
        setOpenResponse(false);
    };

    return (
        <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 overflow-auto w-full">
            <div className='pt-10'>
                <Typography component='span' variant='h4' >IAAM Policy Editor</Typography>
                <Stack direction="row" justifyContent="space-between" sx={{ marginTop: 5 }}>
                    <Stack direction="row" sx={{ border: 1, borderColor: 'rgba(0,0,0,0.2)', borderRadius: 1, padding: 0.1 }}>
                        <Box sx={{ minWidth: 400, maxWidth: 400, bgcolor: theme.palette.white.main, borderTopLeftRadius: 1, borderBottomLeftRadius: 1 }}>
                            <InputBase
                                sx={{ width: '90%', paddingX: 2.5, paddingY: 0.5 }}
                                placeholder="Search"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={handleSearchValueChange}
                            />
                        </Box>
                        <Box sx={{ width: '10%', justifyContent: 'center', alignItems: 'center', display: 'flex', paddingX: 1, bgcolor: theme.palette.white.main, borderTopRightRadius: 1, borderBottomRightRadius: 1 }}>
                            <SearchIcon color="gray" />
                        </Box>
                    </Stack>
                    <Button style={{ textTransform: 'none' }} variant="contained" onClick={handleOpenCreateProject}>
                        + New Project
                    </Button>
                </Stack>
                <div className="overflow-hidden w-full mt-6 h-[calc(100vh-260px)] bg-white">
                    <DataGridPro
                        apiRef={apiRef}
                        loading={isLoading}
                        rows={projects}
                        columns={columns}
                        disableRowSelectionOnClick
                        disableDensitySelector
                        slots={{ loadingOverlay: LinearProgress }}

                    />
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                    </Menu>

                </div>
                <CreateProjectDialog open={open} setOpen={setOpen} setStatusUpdate={setStatusUpdate} />
                <EditProjectDialog open={openEdit} setOpen={setOpenEdit} setStatusUpdate={setStatusUpdate} projectEdit={projectSelected} />
                {/* <ConfirmDialog
                    open={openDelete}
                    setOpen={setOpenDelete}
                    onConfirm={handleDelete}
                    title="Delete Project"
                    description={`Are you sure you want delete "${projectSelected?.project_name !== undefined ? projectSelected.project_name : ""}" ?`}
                    confirmLabel="Delete"
                    confirmColor="error"
                /> */}
                <ConfirmDialogReuse
                    open={openDelete}
                    maxWidth={"sm"}
                    content={
                        <div>
                            <Typography variant="subtitle1">Are you sure you want to delete project "{projectSelected?.project_name}" ?</Typography>
                        </div>
                    }
                    actions={
                        <div className='grid grid-cols-2 gap-2'>
                            <LoadingButton
                                variant="contained"
                                loading={loading}
                                onClick={() => handleDelete()}
                            >
                                Confirm
                            </LoadingButton>
                            <Button
                                disabled={loading}
                                variant="outlined"
                                onClick={() => onDeleteClose()}
                            >
                                Cancel
                            </Button>
                        </div>
                    }
                />
                <ResponseDialog
                    open={openResponse}
                    maxWidth={"sm"}
                    content={
                        <div>
                            <Typography variant="subtitle1">{responseStatus}</Typography>
                        </div>
                    }
                    actions={
                        <Button
                            variant="outlined"
                            onClick={() => onResponseClose()}
                        >
                            Close
                        </Button>
                    }
                />
            </div>
        </section>
    )
}

export default ProjectPage