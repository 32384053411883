import React from 'react'
import { Handle, Position } from '@xyflow/react';
import { Typography, useTheme } from '@mui/material';
import { WarningTooltip } from '../../../../../components/TooltipCustomStyle';
import ErrorIcon from '@mui/icons-material/Error';

const RootNode = ({ data, selected }) => {

    const theme = useTheme();

    return (
        <div style={{
            padding: '10px 15px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100px',
            borderRadius: '50%',
            backgroundColor: '#fff',
            // border: data?.isSelected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[500]}`,
            border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[500]}`,
            position: 'relative',
        }}
        >
            {data?.general_properties?.resource === undefined &&
                <div
                    style={{
                        position: 'absolute',
                        top: '-15px',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 1000,
                        textAlign: 'center',
                        // backgroundColor: '#fff'
                    }}
                >
                    <WarningTooltip title="No Resource data available. Please select the information." arrow placement="top">
                        <ErrorIcon sx={{ color: theme.palette.warning.main, fontSize: 18, marginLeft: '2px', bgcolor: '#fff' }} />
                    </WarningTooltip>
                </div>
            }
            <div style={{
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
            }}>
                <Typography
                    variant='subtitle2'
                    fontSize={{
                        lg: 12,
                        md: 10,
                        sm: 10,
                        xs: 10
                    }}
                    sx={{
                        cursor: 'pointer',
                        textAlign: 'center',
                    }}
                >
                    {data.general_properties.label}
                </Typography>
            </div>
            <Handle
                id="top-target"
                type="target"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: 'transparent',
                    border: 'none',
                }}
                isConnectable={!selected ? true : false}
            />
            <Handle
                id="bottom-target"
                type="target"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: 'transparent',
                    border: 'none',
                }}
                isConnectable={!selected ? true : false}
            />
            <Handle
                id="left-target"
                type="target"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: 'transparent',
                    border: 'none',
                }}
                isConnectable={!selected ? true : false}
            />
            <Handle
                id="right-target"
                type="target"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: 'transparent',
                    border: 'none',
                }}
                isConnectable={!selected ? true : false}
            />
        </div >
    );
};

export default RootNode;