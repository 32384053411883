import { getStraightPath, useInternalNode } from '@xyflow/react';
import React from 'react'
import { getEdgeParams } from '../../../../../utils/reactflowutils';

function CustomEdge({ id, source, target, markerEnd, style }) {
    const sourceNode = useInternalNode(source);
    const targetNode = useInternalNode(target);

    if (!sourceNode || !targetNode) {
        return null;
    }

    const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

    const [edgePath] = getStraightPath({
        sourceX: sx,
        sourceY: sy,
        targetX: tx,
        targetY: ty,
    });

    return (
        <g>
            {/* Hit area (สำหรับคลิก) */}
            <path
                id={`${id}-hitarea`}
                d={edgePath}
                stroke="transparent" // ไม่ให้เส้นนี้แสดงผลจริง
                strokeWidth={10} // ขยายพื้นที่สำหรับการคลิก
                fill="none"
                style={{ pointerEvents: 'stroke' }} // ให้คลิกเฉพาะพื้นที่ stroke
            />
            {/* เส้น edge ที่แสดงผลจริง */}
            <path
                id={id}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
                style={style}
            />
        </g>
    );
};

export default CustomEdge