import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from '../../../../redux/projectSlice';
import { Drawer, styled } from '@mui/material';
import CreateSubjectDialog from './components/CreateSubjectDialog';
import { getOpenDrawerLeft } from '../../../../redux/drgMgtSlice';
import { ReactFlowProvider } from '@xyflow/react';
import SubjectDiagram from './components/SubjectDiagram';
import SubjectFiles from './components/SubjectFiles';
import { setSubjectFile } from '../../../../redux/subjectFileSlice';

const drawerWidth = 250;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.only('xs')]: {
            marginLeft: 0,
        },
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

function SubjectPage() {

    // const project = useSelector(getProject);
    // const project = JSON.parse(localStorage.getItem('project'));
    const dispatch = useDispatch();
    const [openCreate, setOpenCreate] = useState(false);
    const [statusUpdate, setStatusUpdate] = useState(false);
    const [subjectFiles, setSubjectFiles] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const openDrawerLeft = useSelector(getOpenDrawerLeft);
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const [project, setProject] = useState();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const getListSubjects = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/subject-diagram/get-subjects/${project.project.project_id}`);
        if (response.ok) {
            const result = await response.json();
            setSubjectFiles(result);
        } else {
            console.error(response.status);
        }
    }

    const getSubject = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/subject-diagram/get-subject/${selectedItem}`);
        if (response.ok) {
            const result = await response.json();
            dispatch(setSubjectFile(result));
            localStorage.setItem('subject-file', JSON.stringify(result));
        } else {
            console.error(response.status);
        }
    }

    useEffect(() => {
        if (project) {
            getListSubjects();
        }
    }, [project])

    useEffect(() => {
        if (statusUpdate) {
            getListSubjects();
            // setSelectedItem(null);
            getSubject();
            setStatusUpdate(false);
        }
    }, [statusUpdate])

    return (
        <section className="flex w-full overflow-hidden">
            <Drawer
                open={openDrawerLeft}
                variant="persistent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginLeft: "70px", marginTop: "52px" },
                }}
            >
                <SubjectFiles
                    setOpen={setOpenCreate}
                    subjectFiles={subjectFiles}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    setStatusUpdate={setStatusUpdate}
                    className="h-[calc(100%-48px)]"
                    nodes={nodes}
                    edges={edges}
                />
            </Drawer>
            <Main open={openDrawerLeft}>
                <ReactFlowProvider>
                    <SubjectDiagram selectedItem={selectedItem} nodes={nodes} setNodes={setNodes} edges={edges} setEdges={setEdges} />
                </ReactFlowProvider>
            </Main>
            <CreateSubjectDialog open={openCreate} setOpen={setOpenCreate} setStatusUpdate={setStatusUpdate} setSelectedItem={setSelectedItem} />
        </section>
    )
}

export default SubjectPage