import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getOpenRightSide, setOpenRightSide } from '../../../../../redux/openRightSideSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    ReactFlow,
    addEdge,
    MiniMap,
    Controls,
    Background,
    ControlButton,
    useReactFlow,
    MarkerType,
    applyNodeChanges,
    applyEdgeChanges,
    reconnectEdge,
    ConnectionMode,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import { Backdrop, Button, CircularProgress, Drawer, Tooltip, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { getObjectFile } from '../../../../../redux/objectFileSlice';
import RootNode from './RootNode';
import ObjectRightSide from './ObjectRightSide';
import SelectResourceDialog from './SelectResourceDialog';
import ConditionNode from './ConditionNode';
import PrivacyNode from './PrivacyNode';
import ConjunctionNode from './ConjunctionNode';
import CreateConditionDialog from './CreateConditionDialog';
import AttributeSelection from './AttributeSelection';
import SelectionNode from './SelectionNode';
import ConfirmDialogReuse from '../../ReuseComponents/ConfirmDialogReuse';
import ResponseDialog from '../../ReuseComponents/ConfirmDialogReuse';
import { LoadingButton } from '@mui/lab';
import CustomEdge from './CustomEdge';
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import TestSelectionNode from './TestSelectionNode';

const nodeTypes = {
    root: RootNode,
    condition: ConditionNode,
    selection: SelectionNode,
    // selection: TestSelectionNode,
    privacy: PrivacyNode,
    conjunction: ConjunctionNode,
};

const edgeTypes = {
    floating: CustomEdge,
};

const initialNodes = [
    {
        id: uuidv4(),
        root: true,
        type: 'root',
        position: { x: 0, y: 0 },
        data: {
            general_properties: {
                label: 'Root',
                description: '',
            }
        },
    },
];

const defaultEdgeOptions = {
    style: { strokeWidth: 1, stroke: '#ccc' },
    type: 'floating',
    markerEnd: {
        type: MarkerType.ArrowClosed,
        color: '#ccc',
    },
};

const connectionLineStyle = {
    strokeWidth: 1,
    stroke: '#ccc',
};

function ObjectDiagram(props) {

    const { nodes, setNodes, edges, setEdges } = props;
    const dispatch = useDispatch();
    const openRightSide = useSelector(getOpenRightSide);
    // const [nodes, setNodes] = useState([]);
    // const [edges, setEdges] = useState([]);
    const [nodeSelected, setNodeSelected] = useState();
    const [openProperties, setOpenProperties] = useState(false);
    const objectFile = useSelector(getObjectFile);
    const [openSelectResource, setOpenSelectResource] = useState(false);
    const [openCreateCondition, setOpenCreateCondition] = useState(false);
    const [openAttributeSelection, setOpenAttributeSelection] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSave, setOpenSave] = useState(false);
    const [openResponse, setOpenResponse] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [showMinimap, setShowMinimap] = useState(false);
    const [selectedEdgeId, setSelectedEdgeId] = useState(null);
    const [hoveredEdgeId, setHoveredEdgeId] = useState(null);

    const {
        zoomTo,
        getViewport,
        getNodes,
        getEdges,
        setViewport,
        fitView,
        screenToFlowPosition
    } = useReactFlow();

    // const [initialized, setInitialized] = useState(false);

    const drawerWidth = 300;

    const [profile, setProfile] = useState();

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
    }, []);

    const saveViewportState = useCallback(() => {
        const viewport = getViewport();
        const all_nodes = getNodes();
        const all_edges = getEdges();
        const viewport_flow = {
            nodes: all_nodes,
            edges: all_edges,
            viewport: viewport
        }
        const allFlows = JSON.parse(localStorage.getItem('object-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('object-file')) || {};
        allFlows[currentFile.object_diagram_id] = viewport_flow;
        localStorage.setItem('object-diagram', JSON.stringify(allFlows));
    }, [getViewport, getNodes, getEdges]);

    const restoreFlowState = (node_data, edge_data) => {
        const allFlows = JSON.parse(localStorage.getItem('object-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('object-file')) || {};
        const flowState = allFlows[currentFile.object_diagram_id];
        if (flowState) {
            setTimeout(() => {
                setNodes(node_data);
                setEdges(edge_data);
            }, 300);
            setTimeout(() => {
                setViewport(flowState.viewport || { x: 0, y: 0, zoom: 1 });
                setLoading(false);
            }, 500);
        } else {
            setTimeout(() => {
                setNodes(node_data);
                setEdges(edge_data);
            }, 300);
            setTimeout(() => {
                fitView();
                zoomTo(1, { duration: 0 });
                setLoading(false);
            }, 500);
        }
    };

    useEffect(() => {
        setNodeSelected();
        if (objectFile) {
            if (objectFile.data) {
                setLoading(true);
                restoreFlowState(objectFile.data.nodes, objectFile.data.edges);
            } else {
                setLoading(true);
                restoreFlowState(initialNodes, []);
            }
        }
    }, [objectFile])

    const onPaneClick = () => {
        setOpenProperties(false);
        setSelectedEdgeId(null);
        setNodeSelected();
    };

    const onNodeClick = (event, node) => {
        setNodeSelected(node);
        setOpenProperties(true);
    };

    const onEdgeMouseEnter = (event, edge) => {
        setHoveredEdgeId(edge.id);
    };

    const onEdgeMouseLeave = () => {
        setHoveredEdgeId(null);
    };

    const nodesWithUpdatedData = useMemo(() => {
        return nodes.map((node) => {
            if (node.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        isSelected: node.id === nodeSelected?.id,
                    },
                };
            }
            return node;
        });
    }, [nodes]);

    const edgesWithUpdatedData = useMemo(() => {
        return edges.map((edge) => {
            return {
                ...edge,
                style: {
                    ...edge.style,
                    stroke: edge.id === selectedEdgeId ? '#1976d2' : '#9e9e9e',
                    strokeWidth: hoveredEdgeId === edge.id ? 1.5 : 1, // เพิ่มความหนาของเส้นเมื่อ hover หรือเลือก
                },
                // type: 'floating',
                markerEnd: {
                    ...edge.markerEnd,
                    // type: MarkerType.ArrowClosed,
                    color: edge.id === selectedEdgeId ? '#1976d2' : '#9e9e9e',
                },
            };
        });
    }, [edges, selectedEdgeId, hoveredEdgeId]);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const type = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            let newNode = {};

            if (type === 'condition') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type} node`,
                            description: "",
                            conditions: []
                        }
                    },
                };
            } else if (type === 'selection') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type} node`,
                            description: ""
                        }
                    },
                };
            } else if (type === 'privacy') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type} node`,
                            description: ""
                        }
                    },
                };
            } else if (type === 'conjunction') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type} node`,
                            description: ""
                        }
                    },
                };
            }

            setNodes((nds) => nds.concat(newNode));
        },
        [screenToFlowPosition],
    );

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge({
            ...params,
            // type: 'floating',
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
            }
        }, eds)),
        [setEdges],
    );

    const handleSaveDiagram = () => {
        setOpenSave(true);
    };

    useEffect(() => {
        if (nodes.length !== 0) {
            nodes.map((node) => {
                if (node.id === nodeSelected?.id) {
                    let update_current_node = {
                        ...node,
                        data: {
                            ...node.data,
                        },
                    };
                    setNodeSelected(update_current_node);
                }
            });
        }
    }, [nodes, nodeSelected])

    const onNodesChange = useCallback(
        async (changes) => {
            setNodes((nodes) => {
                const oldNodes = nodes.map(
                    (node) => ({
                        ...node,
                        measured: {
                            width: node.measured?.width || 150,
                            height: node.measured?.height || 40,
                        }
                    })
                );
                return applyNodeChanges(changes, oldNodes);
            });
        },
        [setNodes],
    );

    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges],
    );

    const onConnectStart = () => {
        setNodes(nodes => nodes.map(node => ({ ...node, data: { ...node.data, showHandle: true } })));
    };

    const onConnectEnd = (event) => {
        setNodes(nodes => nodes.map(node => ({ ...node, data: { ...node.data, showHandle: false } })));
        if (!event.target.closest('.react-flow__handle')) {
            // console.log('Connection was not made.');
        }
    };

    const onNodeDoubleClick = useCallback(() => {
        if (openRightSide) {
            dispatch(setOpenRightSide(openRightSide));
        } else {
            dispatch(setOpenRightSide(!openRightSide));
        }
    }, [nodes, edges]);

    const onReconnect = useCallback(
        (oldEdge, newConnection) =>
            setEdges((els) => reconnectEdge(oldEdge, newConnection, els)),
        [],
    );

    const onEdgeClick = (event, edge) => {
        setSelectedEdgeId(edge.id);  // กำหนด edge ที่ถูกเลือก
    };

    const handleConfirm = async () => {
        setLoadingDialog(true);
        const now = Date.now();
        let update = {
            object_diagram_id: objectFile.object_diagram_id,
            object_diagram_name: objectFile.object_diagram_name,
            creator_id: objectFile.creator_id,
            creator_name: objectFile.creator_name,
            last_update_id: profile.uprofile.inum,
            last_update_name: profile.uprofile.displayName,
            create_timestamp: objectFile.create_timestamp,
            update_timestamp: now,
            description: objectFile.description,
            complete: true,
        }
        if (nodes.length > 0 || edges.length > 0) {
            update = {
                ...update,
                data: {
                    nodes: nodes,
                    edges: edges
                }
            };
        }

        const requestOptions = {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(update)
        };

        const response = await fetch(process.env.REACT_APP_API_URL + `/object-diagram/update-object/${objectFile.object_diagram_id}`, requestOptions);
        if (response.status === 200) {
            setLoadingDialog(false);
            setOpenSave(false);
            setResponseStatus("Object diagram updated successfully");
            setOpenResponse(true);
        } else {
            console.error(response.status);
            setLoadingDialog(false);
            setResponseStatus(`Error status: ${response.status}`);
            setOpenResponse(true);
        }
    };

    const handleCancel = () => {
        setOpenSave(false);
    };

    const onResponseClose = () => {
        setOpenResponse(false);
    };

    const toggleMinimap = () => {
        setShowMinimap(!showMinimap);
    };

    return (
        <div className='h-[calc(100vh-64px)] relative'>
            {objectFile &&
                <>
                    <ReactFlow
                        // nodes={nodesWithUpdatedData}
                        nodes={nodes}
                        edges={edgesWithUpdatedData}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onNodeClick={onNodeClick}
                        onEdgeClick={onEdgeClick}
                        onPaneClick={onPaneClick}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onConnectStart={onConnectStart}
                        onConnectEnd={onConnectEnd}
                        onReconnect={onReconnect}
                        onMoveEnd={saveViewportState}
                        onNodeDoubleClick={onNodeDoubleClick}
                        // connectionLineComponent={CustomConnectionLine}
                        // defaultEdgeOptions={defaultEdgeOptions}
                        // connectionLineStyle={connectionLineStyle}
                        // reconnectRadius={15}
                        onEdgeMouseEnter={onEdgeMouseEnter}
                        onEdgeMouseLeave={onEdgeMouseLeave}
                    // connectionMode={ConnectionMode.Loose}
                    // fitView
                    >
                        {showMinimap && <MiniMap style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'none',
                            width: 200,
                            border: '1px solid #ccc',
                        }} />}
                        <Controls position="top-left">
                            <ControlButton onClick={() => handleSaveDiagram()}>
                                <Tooltip
                                    title="save"
                                    arrow
                                    PopperProps={{
                                        disablePortal: true, // ทำให้ Tooltip สามารถแสดงนอก layout
                                    }}
                                    placement="right"
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -5],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                >
                                    <SaveIcon />
                                </Tooltip>
                            </ControlButton>
                            <ControlButton onClick={() => toggleMinimap()}>
                                <Tooltip
                                    title="minimap"
                                    arrow
                                    PopperProps={{
                                        disablePortal: true, // ทำให้ Tooltip สามารถแสดงนอก layout
                                    }}
                                    placement="right"
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -5],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                >
                                    {showMinimap ? (
                                        <LayersIcon />
                                    ) : (
                                        <LayersClearIcon />
                                    )}
                                </Tooltip>
                            </ControlButton>
                        </Controls>
                        <Background />
                    </ReactFlow>
                    <Drawer
                        open={openRightSide}
                        anchor="right"
                        variant="persistent"
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginTop: "52px" },
                        }}
                    >
                        <ObjectRightSide
                            nodeSelected={nodeSelected}
                            nodes={nodes}
                            setNodes={setNodes}
                            open={openProperties}
                            setOpen={setOpenProperties}
                            setOpenSelectResource={setOpenSelectResource}
                            setOpenCreateCondition={setOpenCreateCondition}
                            setOpenAttributeSelection={setOpenAttributeSelection}
                        />
                    </Drawer>
                    <SelectResourceDialog
                        open={openSelectResource}
                        setOpen={setOpenSelectResource}
                        nodeSelected={nodeSelected}
                        setNodes={setNodes}
                    />
                    <CreateConditionDialog
                        open={openCreateCondition}
                        setOpen={setOpenCreateCondition}
                        nodeSelected={nodeSelected}
                        nodes={nodes}
                        setNodes={setNodes}
                    />
                    <AttributeSelection
                        open={openAttributeSelection}
                        setOpen={setOpenAttributeSelection}
                    />
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: 1,
                            backgroundColor: 'rgba(220, 220, 220, 1)',
                            position: 'absolute'
                        }}
                        open={loading}
                        onClick={() => setLoading(false)}
                    >
                        <CircularProgress />
                    </Backdrop>
                    <ConfirmDialogReuse
                        open={openSave}
                        maxWidth={"sm"}
                        content={
                            <div>
                                <Typography variant="subtitle1">Are you sure you want to save this diagram?</Typography>
                            </div>
                        }
                        actions={
                            <div className='grid grid-cols-2 gap-2'>
                                <LoadingButton
                                    variant="contained"
                                    disabled={loadingDialog}
                                    loading={loadingDialog}
                                    onClick={() => handleConfirm()}
                                >
                                    Confirm
                                </LoadingButton>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleCancel()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        }
                    />
                    <ResponseDialog
                        open={openResponse}
                        maxWidth={"sm"}
                        content={
                            <div>
                                <Typography variant="subtitle1">{responseStatus}</Typography>
                            </div>
                        }
                        actions={
                            <Button
                                variant="outlined"
                                onClick={() => onResponseClose()}
                            >
                                Close
                            </Button>
                        }
                    />
                </>
            }
        </div>
    )
}

export default ObjectDiagram