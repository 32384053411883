import React from 'react'
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { Box, Stack, useMediaQuery } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function ConfirmDialog(props) {

    const theme = useTheme();
    const { open, setOpen, onConfirm, title, description, confirmLabel, confirmColor, cancelLabel } = props;
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmClick = async () => {
        await onConfirm();
        setOpen(false);
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 2, borderTopRightRadius: 2 }} id="customized-dialog-title">
                    <div>
                        <Typography variant='h6' color={theme.palette.white.main}>{title}</Typography>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant='subtitle1' sx={{ paddingY: 3 }}>{description}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ m: 0, paddingX: 1, paddingY: 1 }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" color={confirmColor ? confirmColor : "primary"} onClick={() => handleConfirmClick()}>{confirmLabel ? confirmLabel : "Confirm"}</Button>
                            <Button variant="contained" color="inherit" onClick={() => handleClose()}>{cancelLabel ? cancelLabel : "Cancel"}</Button>
                        </Stack>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default ConfirmDialog;
