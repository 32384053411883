import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import DialogReuse from '../pages/Editor/DRG/ReuseComponents/DialogReuse';

function EditProjectDialog(props) {
    const { open, setOpen, setStatusUpdate, projectEdit } = props;
    const [projectName, setProjectName] = useState("");
    const [projectNameError, setProjectNameError] = useState(false);
    const [projectNameTextError, setProjectNameTextError] = useState("");
    const [description, setDescription] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [profile, setProfile] = useState();

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
    }, []);

    const handleClose = () => {
        setProjectName("")
        setProjectNameError(false)
        setProjectNameTextError("")
        setDescription("")
        setDisabled(true)
        setOpen(false);
    };

    const handleValidation = async () => {

        const validate = await handleValidate();
        if (validate) {
            const trim_project_name = projectName.trim();
            const now = Date.now();

            const update = {
                project_id: projectEdit.project_id,
                project_name: trim_project_name,
                creator_id: projectEdit.creator_id,
                creator_name: projectEdit.creator_name,
                last_update_id: profile.uprofile.inum,
                last_update_name: profile.uprofile.displayName,
                create_timestamp: projectEdit.create_timestamp,
                update_timestamp: now,
                description: description,
                configuration: projectEdit.configuration
            }

            const requestOptions = {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(update)
            };

            const response = await fetch(process.env.REACT_APP_API_URL + `/project/update-project-info/${projectEdit.project_id}`, requestOptions);
            if (response.status === 200) {
                handleClose();
                setStatusUpdate(true);
            } else {
                console.error(response.status);
            }
        }

    };

    const handleConfirm = () => {
        handleValidation();
    };

    const handleDuplicate = async () => {
        const trim_project_name = projectName.trim();
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                project_name: trim_project_name,
            })
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/project/check-duplicate-project', requestOptions);
        if (response.status === 200) {
            const result = await response.json();
            setDisabled(result.isDuplicate)
            if (result.isDuplicate) {
                setProjectNameError(true)
                setProjectNameTextError("The name already exists in the system.")
                return true;
            } else {
                setProjectNameError(false)
                setProjectNameTextError("")
                return false;
            }
        } else {
            console.error(response.status);
            setProjectNameError(true)
            setProjectNameTextError("Failed to retrieve data. Please try again later.")
            setDisabled(true)
            return true;
        }
    };

    const handleValidate = async () => {
        // ตรวจสอบว่าไม่มีการใส่ชื่อ
        if (!projectName) {
            setProjectNameError(true)
            setDisabled(true)
            setProjectNameTextError("The name field cannot be empty.")
            return false;
        }

        // ตรวจสอบว่าไม่มีช่องว่าง
        if (/\s/.test(projectName)) {
            setProjectNameError(true)
            setDisabled(true)
            setProjectNameTextError("The name should not contain spaces.")
            return false;
        }

        // ตรวจสอบว่ามีเฉพาะตัวอักษรภาษาอังกฤษ ตัวเลข และเครื่องหมาย `_` หรือ `-`
        const regex = /^[a-zA-Z0-9_-]+$/;
        if (!regex.test(projectName)) {
            setProjectNameError(true)
            setDisabled(true)
            setProjectNameTextError("The name should contain only English letters, numbers, underscores (_), or dashes (-).")
            return false;
        }

        if (projectEdit.project_name !== projectName) { // ตรวจสอบว่ามีการแก้ไขชื่อ
            const isDuplicate = await handleDuplicate();
            if (isDuplicate) { // ตรวจสอบว่าไม่มีชื่อซ้ำในระบบ
                return false;
            }
        } else {
            setProjectNameError(false);
            setProjectNameTextError("");
            if (projectEdit.description === description) { // ตรวจสอบว่าไม่มีการแก้ไขคำอธิบาย
                setDisabled(true);
                return false;
            }
        }

        setProjectNameError(false);
        setProjectNameTextError("");
        setDisabled(false);
        return true;
    };

    useEffect(() => {
        if (projectEdit !== undefined && open) {
            setProjectName(projectEdit.project_name)
            setDescription(projectEdit.description)
        }
    }, [projectEdit, open])

    return (
        <Fragment>
            <DialogReuse
                open={open}
                onClose={handleClose}
                maxWidth={"xs"}
                title={"Edit Project"}
                content={
                    <Box
                        component="form"
                        autoComplete="off"
                    >
                        <TextField
                            required
                            id="project-name"
                            label="Project Name"
                            fullWidth
                            margin="normal"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            onBlur={handleValidate}
                            error={projectNameError}
                            helperText={projectNameTextError}
                        />
                        <TextField
                            id="description"
                            label="Description"
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            onBlur={handleValidate}
                        />
                    </Box>
                }
                actions={
                    <Button variant="contained" disabled={disabled} onClick={() => handleConfirm()}>Edit</Button>
                }
            />
        </Fragment>
    );
}

export default EditProjectDialog;
