import { CircularProgress, IconButton, InputBase, Paper, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';


function SearchFiles(props) {
    const { onSearch, onAddClick, isLoading, isOverflow } = props;
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (event) => {
        const term = event.target.value;
        setSearchTerm(term);
        onSearch(term);
    };

    return (
        <Paper
            component="form"
            square
            variant={isOverflow ? "elevation" : "outlined"}
            className='p-2 flex items-center h-[50px] mb-[2px] border-t'
            sx={{ borderLeft: 0, borderRight: 0 }}
        >
            <SearchIcon className='text-gray-400' />
            <InputBase
                // className='bg-[#e5e7eb]'
                sx={{
                    flex: 1,
                    padding: '2px 10px',
                    margin: '0px 4px',
                    borderRadius: 1
                }}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search file' }}
                value={searchTerm}
                onChange={handleSearch}
            />
            <Tooltip
                title="Create"
                arrow
                PopperProps={{
                    disablePortal: true, // ทำให้ Tooltip สามารถแสดงนอก layout
                }}
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -5],
                                },
                            },
                        ],
                    },
                }}
            >
                <IconButton
                    className='flex-col-reverse'
                    style={{
                        height: '24px',
                        width: '24px'
                    }}
                    onClick={() => onAddClick(true)}
                >

                    {isLoading &&
                        <CircularProgress size={20} />
                    }
                    {onAddClick !== undefined && !isLoading &&
                        <AddCircleIcon className='text-gray-400 hover:text-blue-600' />
                    }
                </IconButton>
            </Tooltip>
        </Paper>
    )
}

export default SearchFiles