import React, { useState } from 'react'
import { Handle, Position } from '@xyflow/react';
import { Divider, Typography, useTheme } from '@mui/material';

const ConditionNode = ({ data, selected }) => {

    const theme = useTheme();

    const splitTextOnSecondSpace = (text) => {
        const splitIndex = text.indexOf('request.subject'); // หา index ของ "request."
        if (splitIndex === -1) {
            return text; // ถ้าไม่เจอ "request." ให้แสดงข้อความปกติ
        }
        const firstPart = text.substring(0, splitIndex); // ข้อความก่อน "request."
        const secondPart = text.substring(splitIndex);   // ข้อความตั้งแต่ "request." เป็นต้นไป
        return (
            <>
                <div>
                    {firstPart}
                </div>
                <div>
                    {secondPart}
                </div >
            </>
        );
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '100px',
            backgroundColor: '#fff',
            // border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[500]}`,
            border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[500]}`,
            borderRadius: '5px',
            position: "relative",
        }}
        >
            <div style={{
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                cursor: 'pointer',
                justifyContent: 'center',
                padding: data.general_properties.conditions.length > 0 ? '5px 15px' : '10px 15px',
            }}>
                <Typography
                    variant='subtitle2'
                    fontSize={{
                        lg: 12,
                        md: 10,
                        sm: 10,
                        xs: 10,
                    }}
                >
                    {data.general_properties.label}
                </Typography>
            </div>
            {data?.general_properties?.conditions.length > 0 &&
                <Divider
                    sx={{
                        borderColor: theme.palette.primary.main,
                        borderStyle: 'dashed',
                    }}
                />
            }
            {data?.general_properties?.conditions.length > 0 &&
                <div style={{
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'inline-block',
                    cursor: 'pointer',
                    padding: '5px 15px 5px 15px',
                }}>
                    {data?.general_properties?.conditions.map((item, index) => {
                        return (
                            <Typography
                                key={index}
                                index={index}
                                variant='subtitle2'
                                fontSize={{
                                    lg: 12,
                                    md: 10,
                                    sm: 10,
                                    xs: 10
                                }}
                            >
                                {splitTextOnSecondSpace(item.display)}
                            </Typography>
                        )
                    })}
                </div>
            }
            <Handle
                id="top-target"
                type="target"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="top-source"
                type="source"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="bottom-target"
                type="target"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="bottom-source"
                type="source"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="left-target"
                type="target"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="left-source"
                type="source"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="right-target"
                type="target"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="right-source"
                type="source"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
        </div>
    );
};

export default ConditionNode;