import { createSlice } from '@reduxjs/toolkit'

export const dpesSlice = createSlice({
    name: 'dpeRedux',
    initialState: {
        dpes: [],
    },
    reducers: {
        setDpeRedux: (state, action) => {
            state.dpes = action.payload;
        },
    },
})

export const { setDpeRedux } = dpesSlice.actions;

export const getDpeRedux = (state) => state.dpeRedux.dpes;

export default dpesSlice.reducer