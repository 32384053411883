import { IconButton, Typography, List, ListItem, ListItemText, Menu, MenuItem, ListItemButton, TextField, Button, Box } from '@mui/material'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch } from 'react-redux';
import { clearSubjectFile, setSubjectFile } from '../../../../../redux/subjectFileSlice';
import { setOpenRightSide } from '../../../../../redux/openRightSideSlice';
import SearchFiles from '../../../../../components/SearchFiles';
import DialogReuse from '../../ReuseComponents/DialogReuse';
import ConfirmDialogReuse from '../../ReuseComponents/ConfirmDialogReuse';
import ResponseDialog from '../../ReuseComponents/ConfirmDialogReuse';

function SubjectFiles(props) {
    const { setOpen, subjectFiles, selectedItem, setSelectedItem, setStatusUpdate, nodes, edges } = props;
    const overflowingListFile = React.createRef();
    const [overflowActive, setOverflowActive] = useState(false);
    const dispatch = useDispatch();
    const [filteredItems, setFilteredItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const prevSelectIdFile = useRef();
    const [selectFile, setSelectFile] = useState();
    const [fileData, setFileData] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [subjectDiagramName, setSubjectDiagramName] = useState("");
    const [subjectDiagramNameError, setSubjectDiagramNameError] = useState(false);
    const [subjectDiagramNameTextError, setSubjectDiagramNameTextError] = useState("");
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    const [description, setDescription] = useState("");
    const [profile, setProfile] = useState();
    const [project, setProject] = useState();
    // const [loading, setLoading] = useState(false);
    const [openResponse, setOpenResponse] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
    }, []);

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const handleClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setFileData(item)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const selectFile = JSON.parse(localStorage.getItem('subject-file'));
        if (selectFile) {
            setSelectFile(selectFile);
        }
    }, []);

    const getSelectSubjectFile = async (subject_diagram_id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/subject-diagram/get-subject/${subject_diagram_id}`);
        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            console.error(response.status);
            return;
        }
    };

    const onClickSubjectFile = async (item) => {
        if (selectedItem !== item.subject_diagram_id) {
            setSelectedItem(item.subject_diagram_id);
            const subject_file = await getSelectSubjectFile(item.subject_diagram_id);
            dispatch(setSubjectFile(subject_file));
            localStorage.setItem('subject-file', JSON.stringify(subject_file));
        }
    };

    const handleSearch = (term) => {
        const filtered = subjectFiles.filter(item =>
            item.subject_diagram_name.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredItems(filtered);
    };

    const onClickEdit = () => {
        handleClose();
        setSubjectDiagramName(fileData.subject_diagram_name);
        setDescription(fileData.description);
        setOpenEdit(true);
    };

    const onClickDelete = () => {
        handleClose();
        setOpenDelete(true);
    };

    const onEditClose = () => {
        setSubjectDiagramNameError(false);
        setSubjectDiagramNameTextError("");
        setConfirmDisabled(true);
        setOpenEdit(false);
    };

    const onDeleteClose = () => {
        setOpenDelete(false);
    };

    useEffect(() => {
        if (subjectFiles) {
            setFilteredItems(subjectFiles);
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [subjectFiles])

    useLayoutEffect(() => {
        const { current } = overflowingListFile;
        const trigger = () => {
            const hasOverflow = current.scrollHeight > current.clientHeight;
            setOverflowActive(hasOverflow);
        };

        if (current) {
            if ('ResizeObserver' in window) {
                new ResizeObserver(trigger).observe(current);
            }
            trigger();
        }

    }, [overflowingListFile]);

    useEffect(() => {
        if (selectFile) {
            if (prevSelectIdFile.current === undefined && selectFile.subject_diagram_id) {
                const getLocalStorageFile = async (subject_diagram_id) => {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/subject-diagram/get-subject/${subject_diagram_id}`);
                    if (response.ok) {
                        const result = await response.json();
                        setSelectedItem(result.subject_diagram_id);
                        dispatch(setSubjectFile(result));
                        localStorage.setItem('subject-file', JSON.stringify(result));
                    } else {
                        console.error(response.status);
                    }
                };
                getLocalStorageFile(selectFile.subject_diagram_id);
                prevSelectIdFile.current = selectFile.subject_diagram_id;
            }
        }
    }, [selectFile])

    const handleValidate = async () => {
        // ตรวจสอบว่าไม่มีการใส่ชื่อ
        if (!subjectDiagramName) {
            setSubjectDiagramNameError(true)
            setConfirmDisabled(true)
            setSubjectDiagramNameTextError("The name field cannot be empty.")
            return false;
        }

        // ตรวจสอบว่าไม่มีช่องว่าง
        if (/\s/.test(subjectDiagramName)) {
            setSubjectDiagramNameError(true)
            setConfirmDisabled(true)
            setSubjectDiagramNameTextError("The name should not contain spaces.")
            return false;
        }

        // ตรวจสอบว่ามีเฉพาะตัวอักษรภาษาอังกฤษ ตัวเลข และเครื่องหมาย `_` หรือ `-`
        const regex = /^[a-zA-Z0-9_-]+$/;
        if (!regex.test(subjectDiagramName)) {
            setSubjectDiagramNameError(true)
            setConfirmDisabled(true)
            setSubjectDiagramNameTextError("The name should contain only English letters, numbers, underscores (_), or dashes (-).")
            return false;
        }

        if (fileData.subject_diagram_name !== subjectDiagramName) { // ตรวจสอบว่ามีการแก้ไขชื่อ
            const isDuplicate = await handleDuplicate();
            if (isDuplicate) { // ตรวจสอบว่าไม่มีชื่อซ้ำในระบบ
                return false;
            }
        } else {
            setSubjectDiagramNameError(false);
            setSubjectDiagramNameTextError("");
            if (fileData.description === description) { // ตรวจสอบว่าไม่มีการแก้ไขคำอธิบาย
                setConfirmDisabled(true);
                return false;
            }
        }

        setSubjectDiagramNameError(false);
        setSubjectDiagramNameTextError("");
        setConfirmDisabled(false);
        return true;
    };

    const handleDuplicate = async () => {
        const trim_subject_name = subjectDiagramName.trim();
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                project_id: project.project.project_id,
                subject_diagram_name: trim_subject_name
            })
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/subject-diagram/check-duplicate-subject', requestOptions);
        if (response.status === 200) {
            const result = await response.json();
            setConfirmDisabled(result.isDuplicate)
            if (result.isDuplicate) {
                setSubjectDiagramNameError(true)
                setSubjectDiagramNameTextError("The name already exists in the system.")
                return true;
            } else {
                setSubjectDiagramNameError(false)
                setSubjectDiagramNameTextError("")
                return false;
            }
        } else {
            console.error(response.status);
            setSubjectDiagramNameError(true)
            setConfirmDisabled(true)
            setSubjectDiagramNameTextError("Failed to retrieve data. Please try again later.")
            return true;
        }
    };

    const handleEdit = async () => {

        const validate = await handleValidate();
        if (validate) {
            const trim_subject_name = subjectDiagramName.trim();
            const update_ts = Date.now();

            const requestOptions = {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    subject_diagram_id: fileData.subject_diagram_id,
                    subject_diagram_name: trim_subject_name,
                    creator_id: fileData.creator_id,
                    creator_name: fileData.creator_name,
                    last_update_id: profile.uprofile.inum,
                    last_update_name: profile.uprofile.displayName,
                    create_timestamp: fileData.create_ts,
                    update_timestamp: update_ts,
                    description: description,
                    complete: true,
                    data: {
                        nodes: nodes,
                        edges: edges
                    }
                })
            };

            const response = await fetch(process.env.REACT_APP_API_URL + '/subject-diagram/update-subject/' + fileData.subject_diagram_id, requestOptions);
            if (response.status === 200) {
                setStatusUpdate(true);
                // setSelectedItem(fileData.subject_diagram_id);
                onEditClose();
            } else {
                console.error(response.status);
            }
        }

    };

    const handleDelete = async () => {
        const selected_id = [fileData?.subject_diagram_id.toString()];
        const requestOptions = {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                subject_ids: selected_id
            })
        };

        const response = await fetch(process.env.REACT_APP_API_URL + '/subject-diagram/delete-subjects', requestOptions);
        if (response.status === 200) {
            if (fileData?.subject_diagram_id === selectFile?.subject_diagram_id) {
                dispatch(clearSubjectFile());
                localStorage.removeItem('subject-file');
                setSelectedItem(null);
            }
            setStatusUpdate(true);
            onDeleteClose();
            setResponseStatus("Selected subject file deleted successfully");
            setOpenResponse(true);
        } else {
            console.error(response.status);
            setResponseStatus(`Error status: ${response.status}`);
            setOpenResponse(true);
        }
    };

    const onResponseClose = () => {
        setOpenResponse(false);
    };

    return (
        <div className='h-full bg-white'>
            <Typography variant='body2' className='px-3 py-3 text-gray-500'>Subject Files</Typography>
            <SearchFiles onSearch={handleSearch} onAddClick={setOpen} isOverflow={overflowActive} isLoading={isLoading} />
            <List ref={overflowingListFile} className='h-[calc(100%-150px)]' sx={{ padding: 0, overflowY: "auto" }} >
                {filteredItems.map((item, index) => {
                    return (
                        <ListItem
                            key={item.subject_diagram_id}
                            disablePadding
                            className='border-b hover:bg-slate-50 cursor-pointer'
                            sx={selectedItem === item.subject_diagram_id ?
                                {
                                    backgroundColor: 'rgba(25,118,210,0.1)'
                                } : undefined
                            }
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    aria-label="more"
                                    sx={{ width: '24px', height: '24px', right: 3 }}
                                    id="basic-button"
                                    aria-controls={open ? 'menu-action' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={(e) => handleClick(e, item)}
                                >
                                    <MoreVertIcon fontSize="small" className='text-gray-400' />
                                </IconButton>
                            }
                        >
                            <ListItemButton
                                role={undefined}
                                onClick={() => onClickSubjectFile(item)}
                                dense
                            >
                                <ListItemText
                                    className='text-gray-500 py-1'
                                    primary={item.subject_diagram_name}
                                    primaryTypographyProps={{
                                        fontSize: 14,
                                        paddingLeft: '12px',
                                        color: selectedItem === item.subject_diagram_id ? '#1976d2' : undefined,
                                        fontWeight: (selectedItem === item.subject_diagram_id) ? 'bold' : undefined
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
            <Menu
                id="menu-action"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={onClickEdit}>Edit</MenuItem>
                <MenuItem onClick={onClickDelete}>Delete</MenuItem>
            </Menu>
            <DialogReuse
                open={openEdit}
                onClose={onEditClose}
                maxWidth={"xs"}
                title={"Edit Subject File"}
                content={
                    <Box
                        component="form"
                        autoComplete="off"
                    >
                        <TextField
                            required
                            id="subject-name"
                            label="Subject Name"
                            fullWidth
                            margin="normal"
                            value={subjectDiagramName}
                            onChange={(e) => setSubjectDiagramName(e.target.value)}
                            onBlur={handleValidate}
                            error={subjectDiagramNameError}
                            helperText={subjectDiagramNameTextError}
                        />
                        <TextField
                            id="description"
                            label="Description"
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            onBlur={handleValidate}
                        />
                    </Box>
                }
                actions={
                    <Button
                        variant="contained"
                        disabled={confirmDisabled}
                        onClick={() => handleEdit()}
                    >
                        Edit
                    </Button>
                }
            />
            <ConfirmDialogReuse
                open={openDelete}
                maxWidth={"sm"}
                content={
                    <div>
                        <Typography variant="subtitle1">Are you sure you want to delete subject file "{fileData?.subject_diagram_name}" ?</Typography>
                    </div>
                }
                actions={
                    <div className='grid grid-cols-2 gap-2'>
                        <Button
                            variant="contained"
                            onClick={() => handleDelete()}
                        >
                            Confirm
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => onDeleteClose()}
                        >
                            Cancel
                        </Button>
                    </div>
                }
            />
            <ResponseDialog
                open={openResponse}
                maxWidth={"sm"}
                content={
                    <div>
                        <Typography variant="subtitle1">{responseStatus}</Typography>
                    </div>
                }
                actions={
                    <Button
                        variant="outlined"
                        onClick={() => onResponseClose()}
                    >
                        Close
                    </Button>
                }
            />
        </div>
    )
}

export default SubjectFiles