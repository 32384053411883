import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getOpenRightSide, setOpenRightSide } from '../../../../../redux/openRightSideSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    ReactFlow,
    addEdge,
    MiniMap,
    Controls,
    Background,
    ControlButton,
    useReactFlow,
    MarkerType,
    applyNodeChanges,
    applyEdgeChanges,
    reconnectEdge,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import { Backdrop, Button, CircularProgress, Drawer, Tooltip, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import RegulatoryRightSide from './RegulatoryRightSide';
import { getRegulatoryFile } from '../../../../../redux/regulatoryFileSlice';
import SubjectRegulatoryNode from './SubjectRegulatoryNode';
import ConditionRegulatoryNode from './ConditionRegulatoryNode';
import CreateSubjectConstraintDialog from '../../Subject/components/CreateSubjectConstraintDialog';
import SelectResourceDialog from '../../Object/components/SelectResourceDialog';
import AttributeSelection from '../../Object/components/AttributeSelection';
import CreateConditionDialog from '../../Object/components/CreateConditionDialog';
import GetAppIcon from '@mui/icons-material/GetApp';
import SelectionRegulatoryNode from './SelectionRegulatoryNode';
import ConjunctionRegulatoryNode from './ConjunctionRegulatoryNode';
import PrivacyRegulatoryNode from './PrivacyRegulatoryNode';
import RootRegulatoryNode from './RootRegulatoryNode';
import ConfirmDialogReuse from '../../ReuseComponents/ConfirmDialogReuse';
import ResponseDialog from '../../ReuseComponents/ConfirmDialogReuse';
import { LoadingButton } from '@mui/lab';
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';

const nodeTypes = {
    root: RootRegulatoryNode,
    condition: ConditionRegulatoryNode,
    selection: SelectionRegulatoryNode,
    privacy: PrivacyRegulatoryNode,
    conjunction: ConjunctionRegulatoryNode,
    subject: SubjectRegulatoryNode
};

function RegulatoryDiagram(props) {

    const { nodes, setNodes, edges, setEdges } = props;
    const dispatch = useDispatch();
    const openRightSide = useSelector(getOpenRightSide);
    const regulatoryFile = useSelector(getRegulatoryFile);
    const [nodeSelected, setNodeSelected] = useState();
    const [openProperties, setOpenProperties] = useState(false);
    const { screenToFlowPosition, setCenter } = useReactFlow();
    const [profile, setProfile] = useState();
    const [openSave, setOpenSave] = useState(false);
    const [openResponse, setOpenResponse] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [showMinimap, setShowMinimap] = useState(false);

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
    }, []);

    //Subject
    const [openCreateSubjectConstraint, setOpenCreateSubjectConstraint] = useState(false);

    //Object
    const [openSelectResource, setOpenSelectResource] = useState(false);
    const [openAttributeSelection, setOpenAttributeSelection] = useState(false);
    const [openCreateCondition, setOpenCreateCondition] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        zoomTo,
        getViewport,
        getNodes,
        getEdges,
        setViewport,
        fitView
    } = useReactFlow();

    // const [initialized, setInitialized] = useState(false);

    const drawerWidth = 300;

    const saveViewportState = useCallback(() => {
        const viewport = getViewport();
        const all_nodes = getNodes();
        const all_edges = getEdges();
        const viewport_flow = {
            nodes: all_nodes,
            edges: all_edges,
            viewport: viewport
        }
        const allFlows = JSON.parse(localStorage.getItem('regulatory-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('regulatory-file')) || {};
        allFlows[currentFile.regulatory_diagram_id] = viewport_flow;
        localStorage.setItem('regulatory-diagram', JSON.stringify(allFlows));
    }, [getViewport, getNodes, getEdges]);

    const restoreFlowState = (node_data, edge_data) => {
        const allFlows = JSON.parse(localStorage.getItem('regulatory-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('regulatory-file')) || {};
        const flowState = allFlows[currentFile.regulatory_diagram_id];
        if (flowState) {
            setTimeout(() => {
                setNodes(node_data);
                setEdges(edge_data);
            }, 300);
            setTimeout(() => {
                setViewport(flowState.viewport || { x: 0, y: 0, zoom: 1 });
                setLoading(false);
            }, 500);
        } else {
            setTimeout(() => {
                setNodes(node_data);
                setEdges(edge_data);
            }, 300);
            setTimeout(() => {
                fitView();
                zoomTo(1, { duration: 0 });
                setLoading(false);
            }, 500);
        }
    };

    useEffect(() => {
        if (regulatoryFile) {
            setNodeSelected(undefined);
            if (regulatoryFile.data) {
                console.log('regulatoryFile.data: ', regulatoryFile.data);
                setLoading(true);
                restoreFlowState(regulatoryFile.data.nodes, regulatoryFile.data.edges);
            } else {
                setLoading(true);
                restoreFlowState([], []);
            }
        }
    }, [regulatoryFile])

    const onPaneClick = () => {
        setNodeSelected(undefined);
        setOpenProperties(false);
    };

    const onNodeClick = (event, node) => {
        setNodeSelected(node);
        setOpenProperties(true);
    };

    const renderedNodes = useMemo(() => {
        return nodes.map((node) => {
            if (node.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        isSelected: node.id === nodeSelected?.id,
                    },
                    deletable: false
                };
            }
            return {
                ...node,
                deletable: false, // บล็อกการลบ node ทุกตัว
            };
        });
    }, [nodes, nodeSelected]);

    const renderedEdges = useMemo(() => {
        return edges.map((edge) => {
            if (edge?.markerEnd?.color === 'red') {
                return {
                    ...edge,
                    deletable: true, // บล็อกการลบ node ทุกตัว
                };
            } else {
                return {
                    ...edge,
                    deletable: false, // บล็อกการลบ node ทุกตัว
                };
            }
        });
    }, [edges]);

    const updatePosition = async (result, position) => {

        const newNodes = await result.data.nodes.map(node => ({
            ...node,
            position: {
                x: node.position.x + position.x,
                y: node.position.y + position.y,
                zoom: 1
            },
        }));

        const newEdges = await result.data.edges.map(edge => ({
            ...edge,
            source: newNodes.find(n => n.id === edge.source)?.id || edge.source,
            target: newNodes.find(n => n.id === edge.target)?.id || edge.target,
        }));

        return { nodes: newNodes, edges: newEdges };

    };

    const fetchData = async (type, id, position) => {
        if (type === "subject") {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/subject-diagram/get-subject/${id}`);
            if (response.ok) {
                const result = await response.json();
                return updatePosition(result, position);
            } else {
                console.error('Error fetching data');
                return { nodes: [], edges: [] };
            }
        } else if (type === "object") {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/object-diagram/get-object/${id}`);
            if (response.ok) {
                const result = await response.json();
                return updatePosition(result, position);
            } else {
                console.error('Error fetching data');
                return { nodes: [], edges: [] };
            }
        }
    };

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        async (event) => {
            event.preventDefault();

            const nodeData = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (typeof nodeData === 'undefined' || !nodeData) {
                return;
            }

            const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            const positionView = {
                x: event.clientX,
                y: event.clientY,
            };

            const objectData = JSON.parse(nodeData);
            const { nodes: newNodes, edges: newEdges } = await fetchData(objectData.type, objectData.diagram_id, position);

            if (newNodes.length > 0) {
                setNodes((nds) => nds.concat(newNodes));
                setEdges((eds) => eds.concat(newEdges));
                setViewport(positionView.x, positionView.y, { zoom: 1, duration: 800 });
            }

        },
        [screenToFlowPosition],
    );

    const handleSaveDiagram = () => {
        setOpenSave(true);
    };

    useEffect(() => {
        if (nodes.length !== 0) {
            nodes.map((node) => {
                if (node.id === nodeSelected?.id) {
                    let update_current_node = {
                        ...node,
                        data: {
                            ...node.data,
                        },
                    };
                    setNodeSelected(update_current_node);
                }
            });
        }
    }, [nodes])

    const onNodesChange = useCallback(
        async (changes) => {
            const filteredChanges = changes.filter(change => change.type !== 'remove');
            setNodes((nodes) => {
                const oldNodes = nodes.map(
                    (node) => ({
                        ...node,
                        measured: {
                            width: node.measured?.width || 150,
                            height: node.measured?.height || 40,
                        }
                    })
                );
                return filteredChanges.length > 0 ? applyNodeChanges(changes, oldNodes) : applyNodeChanges(changes, oldNodes);
            });
        },
        [setNodes],
    );

    const onEdgesChange = useCallback(
        (changes) => {
            const filteredChanges = changes.filter(change => change.type !== 'remove');
            setEdges((eds) => filteredChanges.length > 0 ? applyEdgeChanges(changes, eds) : applyEdgeChanges(changes, eds));
        },
        [setEdges],
    );

    const onNodeDoubleClick = useCallback(() => {
        if (openRightSide) {
            dispatch(setOpenRightSide(openRightSide));
        } else {
            dispatch(setOpenRightSide(!openRightSide));
        }
    }, [nodes, edges]);

    const shouldShowHandle = (start_node, node) => {
        if (start_node.type === "subject" && node.type !== "subject") {
            return true;
        } else if (start_node.type !== "subject" && node.type === "subject") {
            return true;
        } else {
            return false;
        }
    };

    const onConnect = useCallback(
        (params) => {
            const sourceNode = nodes.find((node) => node.id === params.source);
            const targetNode = nodes.find((node) => node.id === params.target);

            // ตรวจสอบเงื่อนไขการเชื่อม node
            const validateNodes = shouldShowHandle(sourceNode, targetNode);
            if (validateNodes) {
                setEdges((eds) => addEdge({
                    ...params,
                    style: { stroke: 'red', strokeWidth: 1 },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 20,
                        height: 20,
                        color: 'red',
                    }
                }, eds));
            }
        },
        [setEdges, shouldShowHandle],
    );

    const onConnectStart = useCallback(
        (event, params) => {
            const start_node = nodes.find((n) => n.id === params.nodeId);
            if (start_node.type === "subject") {
                console.log('onConnectStart', start_node.type);
                setNodes(nodes => nodes.map(node => ({
                    ...node,
                    data: { ...node.data, showHandle: node.type !== "subject" ? true : false }
                })));
            }
        },
        [nodes, setNodes],
    );

    const onConnectEnd = useCallback(
        (event) => {
            setNodes(nodes => nodes.map(node => ({ ...node, data: { ...node.data, showHandle: false } })));
            if (!event.target.closest('.react-flow__handle')) {
                console.log('Connection was not made.');
            }
        },
        [nodes, setNodes],
    );

    const shouldShowReconnectHandle = (start_node, node) => {
        if (start_node.type === 'subject') {
            if (start_node.type === node.type) {
                return true;
            } else {
                return false;
            }
        }
        if (start_node.type !== 'subject') {
            if (start_node.id === node.id) {
                return true;
            } else {
                return false;
            }
        }
    };

    const onReconnectStart = useCallback((event, newConnection, handleType) => {
        if (handleType === "source") {
            const show_handle_node = nodes.find((n) => n.id === newConnection.target);
            setNodes(nodes => nodes.map((node) => ({
                ...node,
                data: { ...node.data, showHandle: shouldShowReconnectHandle(show_handle_node, node) }
            })));
        } else {
            const show_handle_node = nodes.find((n) => n.id === newConnection.source);
            setNodes(nodes => nodes.map((node) => ({
                ...node,
                data: { ...node.data, showHandle: shouldShowReconnectHandle(show_handle_node, node) }
            })));
        }
    }, [setNodes, shouldShowReconnectHandle]);

    const onReconnect = useCallback((oldEdge, newConnection) => {
        if (oldEdge.markerEnd.color === 'red') {
            const sourceNode = nodes.find((node) => node.id === newConnection.source);
            const targetNode = nodes.find((node) => node.id === newConnection.target);
            // // ตรวจสอบเงื่อนไขการเชื่อม node
            const validateNodes = shouldShowHandle(sourceNode, targetNode);
            if (validateNodes) {
                setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
            }
        } else {
            if (oldEdge.source === newConnection.source && oldEdge.target === newConnection.target) {
                setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
            }
        }
    }, [setEdges, shouldShowHandle]);

    const handleExportDiagram = () => {
        createRegulationGraph(regulatoryFile.regulatory_diagram_id);
    };

    const createRegulationGraph = async (regulatory_id) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/regulatory-diagram/get-regulatory/${regulatory_id}`);
        const dataJson = await response.json();

        const regulation_graph = {
            subject_graph: {
                nodes: {},
                edges: {}
            },
            regulatory: {},
            object_graph: {
                nodes: {},
                edges: {}
            }
        }

        const regulationData = dataJson.data;
        const nodeData = regulationData.nodes;
        const edgeData = regulationData.edges;

        for (let index = 0; index < nodeData.length; index++) {
            const element = nodeData[index];
            if (element.type === 'subject') {
                if (element.root) {
                    regulation_graph.subject_graph.nodes[element.id] = {
                        label: element.data.general_properties.label,
                        root: true
                    }
                } else {
                    regulation_graph.subject_graph.nodes[element.id] = {
                        label: element.data.general_properties.label,
                        expression: element.data.general_properties.expression
                    }
                }
            } else {
                let params = {};
                if (element.type === "root") {
                    params = {
                        resource: element.data.general_properties.resource.resource_label
                    }
                } else if (element.type === "condition") {
                    if (element.data.general_properties.conditions.length > 0) {
                        params = {
                            expression: element.data.general_properties.expression
                        }
                    }
                } else if (element.type === "privacy") {
                    let privacys = element.data.general_properties.privacys;
                    for (let index = 0; index < privacys.length; index++) {
                        privacys[index]["attribute"] = privacys[index].attribute.name;
                    }
                    params = {
                        privacy_aspect: privacys
                    }
                } else if (element.type === "selection") {
                    params = {
                        selection: element.data.general_properties.selection
                    }
                }
                regulation_graph.object_graph.nodes[element.id] = {
                    type: element.type,
                    label: element.data.general_properties.label,
                    ...params
                }
            }
        }

        for (let index = 0; index < edgeData.length; index++) {
            const element = edgeData[index];
            const source = element.source;
            const target = element.target;
            let sourceType = "object";
            let targetType = "object";
            if (regulation_graph.subject_graph.nodes[source] !== undefined) {
                sourceType = "subject";
            }
            if (regulation_graph.subject_graph.nodes[target] !== undefined) {
                targetType = "subject";
            }
            if (sourceType === targetType) {
                if (sourceType === "subject") {
                    if (regulation_graph.subject_graph.edges[source] === undefined) {
                        regulation_graph.subject_graph.edges[source] = {
                            to: [{
                                node: target
                            }]
                        }
                    } else {
                        regulation_graph.subject_graph.edges[source].to.push({
                            node: target
                        })
                    }
                } else {
                    if (regulation_graph.object_graph.edges[source] === undefined) {
                        regulation_graph.object_graph.edges[source] = {
                            to: [{
                                node: target
                            }]
                        }
                    } else {
                        regulation_graph.object_graph.edges[source].to.push({
                            node: target
                        })
                    }
                }
            } else {
                if (regulation_graph.regulatory[source] === undefined) {
                    regulation_graph.regulatory[source] = {
                        association: [{
                            node: target
                        }]
                    }
                } else {
                    regulation_graph.regulatory[source].association.push({
                        node: target
                    })
                }
            }
        }

        // writefile downloadfile senttoMongoDB
        const jsonBlob = new Blob([JSON.stringify(regulation_graph, null, 2)], { type: 'application/json' });

        // สร้างลิงก์ URL สำหรับดาวน์โหลดไฟล์
        const url = URL.createObjectURL(jsonBlob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${regulatoryFile.regulatory_diagram_name}.json`;
        link.click();

        // ล้าง URL หลังจากการดาวน์โหลดเสร็จสิ้น
        URL.revokeObjectURL(url);

        console.log("Download File Success");

    }

    const handleConfirm = async () => {
        setLoadingDialog(true);
        const now = Date.now();
        let update = {
            regulatory_diagram_id: regulatoryFile.regulatory_diagram_id,
            regulatory_diagram_name: regulatoryFile.regulatory_diagram_name,
            creator_id: regulatoryFile.creator_id,
            creator_name: regulatoryFile.creator_name,
            last_update_id: profile.uprofile.inum,
            last_update_name: profile.uprofile.displayName,
            create_timestamp: regulatoryFile.create_timestamp,
            update_timestamp: now,
            description: regulatoryFile.description,
            complete: true,
            data: {
                nodes: nodes,
                edges: edges
            }
        }

        const requestOptions = {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(update)
        };

        const response = await fetch(process.env.REACT_APP_API_URL + `/regulatory-diagram/update-regulatory/${regulatoryFile.regulatory_diagram_id}`, requestOptions);
        if (response.status === 200) {
            setLoadingDialog(false);
            setOpenSave(false);
            setResponseStatus("Regulatory diagram updated successfully");
            setOpenResponse(true);
        } else {
            console.error(response.status);
            setLoadingDialog(false);
            setResponseStatus(`Error status: ${response.status}`);
            setOpenResponse(true);
        }
    };

    const handleCancel = () => {
        setOpenSave(false);
    };

    const onResponseClose = () => {
        setOpenResponse(false);
    };

    const toggleMinimap = () => {
        setShowMinimap(!showMinimap);
    };

    return (
        <div className='h-[calc(100vh-64px)] relative'>
            {regulatoryFile &&
                <>
                    <ReactFlow
                        nodes={renderedNodes}
                        edges={renderedEdges}
                        nodeTypes={nodeTypes}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onNodeClick={onNodeClick}
                        onPaneClick={onPaneClick}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onMoveEnd={saveViewportState}
                        onConnectStart={onConnectStart}
                        onConnectEnd={onConnectEnd}
                        onNodeDoubleClick={onNodeDoubleClick}
                        onReconnect={onReconnect}
                        onReconnectStart={onReconnectStart}
                        // fitView
                        connectionLineStyle={{ stroke: '#1976d2', strokeWidth: 1 }}
                    >
                        {showMinimap && <MiniMap style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'none',
                            width: 200,
                            border: '1px solid #ccc',
                        }} />}
                        <Controls position="top-left">
                            <ControlButton onClick={() => handleSaveDiagram()}>
                                <Tooltip
                                    title="save"
                                    arrow
                                    PopperProps={{
                                        disablePortal: true, // ทำให้ Tooltip สามารถแสดงนอก layout
                                    }}
                                    placement="right"
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -5],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                >
                                    <SaveIcon />
                                </Tooltip>
                            </ControlButton>
                            <ControlButton onClick={() => toggleMinimap()}>
                                <Tooltip
                                    title="minimap"
                                    arrow
                                    PopperProps={{
                                        disablePortal: true, // ทำให้ Tooltip สามารถแสดงนอก layout
                                    }}
                                    placement="right"
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -5],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                >
                                    {showMinimap ? (
                                        <LayersIcon />
                                    ) : (
                                        <LayersClearIcon />
                                    )}
                                </Tooltip>
                            </ControlButton>
                            <ControlButton onClick={() => handleExportDiagram()}>
                                <GetAppIcon />
                            </ControlButton>
                        </Controls>
                        <Background />
                    </ReactFlow>
                    <Drawer
                        open={openRightSide}
                        anchor="right"
                        variant="persistent"
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginTop: "52px" },
                        }}
                    >
                        <RegulatoryRightSide
                            nodeSelected={nodeSelected}
                            nodes={nodes}
                            setNodes={setNodes}
                            open={openProperties}
                            setOpen={setOpenProperties}
                            setOpenCreateSubjectConstraint={setOpenCreateSubjectConstraint}
                            setOpenSelectResource={setOpenSelectResource}
                            setOpenCreateCondition={setOpenCreateCondition}
                            setOpenAttributeSelection={setOpenAttributeSelection}
                        />
                    </Drawer>
                    <CreateSubjectConstraintDialog
                        open={openCreateSubjectConstraint}
                        setOpen={setOpenCreateSubjectConstraint}
                        setNodes={setNodes}
                        nodeSelected={nodeSelected}
                    />
                    <SelectResourceDialog
                        open={openSelectResource}
                        setOpen={setOpenSelectResource}
                        nodeSelected={nodeSelected}
                        setNodes={setNodes}
                    />
                    <CreateConditionDialog
                        open={openCreateCondition}
                        setOpen={setOpenCreateCondition}
                        nodeSelected={nodeSelected}
                        nodes={nodes}
                        setNodes={setNodes}
                    />
                    <AttributeSelection
                        open={openAttributeSelection}
                        setOpen={setOpenAttributeSelection}
                    />
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: 1,
                            backgroundColor: 'rgba(220, 220, 220, 1)',
                            position: 'absolute'
                        }}
                        open={loading}
                        onClick={() => setLoading(false)}
                    >
                        <CircularProgress />
                    </Backdrop>
                    <ConfirmDialogReuse
                        open={openSave}
                        maxWidth={"sm"}
                        content={
                            <div>
                                <Typography variant="subtitle1">Are you sure you want to save this diagram?</Typography>
                            </div>
                        }
                        actions={
                            <div className='grid grid-cols-2 gap-2'>
                                <LoadingButton
                                    variant="contained"
                                    disabled={loadingDialog}
                                    loading={loadingDialog}
                                    onClick={() => handleConfirm()}
                                >
                                    Confirm
                                </LoadingButton>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleCancel()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        }
                    />
                    <ResponseDialog
                        open={openResponse}
                        maxWidth={"sm"}
                        content={
                            <div>
                                <Typography variant="subtitle1">{responseStatus}</Typography>
                            </div>
                        }
                        actions={
                            <Button
                                variant="outlined"
                                onClick={() => onResponseClose()}
                            >
                                Close
                            </Button>
                        }
                    />
                </>
            }
        </div>
    )
}

export default RegulatoryDiagram