import { Dialog, DialogActions, DialogContent } from '@mui/material'
import React, { Fragment } from 'react'

function ConfirmDialogReuse(props) {

    const { open, content, actions, maxWidth } = props;

    return (
        <Fragment>
            <Dialog
                maxWidth={maxWidth}
                open={open}
            >
                <DialogContent dividers>
                    <div className='py-5'>
                        {content}
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='px-4 py-1'>
                        {actions}
                    </div>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default ConfirmDialogReuse